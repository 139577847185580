import React from 'react'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Meta from 'components/meta'
import Wrapper from 'components/wrapper'

import { useSiteMetadata } from 'utils/use-site-metadata'

interface Props {
  location: Location
}

const NotFound = ({ location }: Props) => {
  const { siteTitle } = useSiteMetadata()
  return (
    <Wrapper location={location} className="w-100 mx-auto d-flex flex-column bg-body-dark">
      <Meta title="Not Found" />
      <Row className="py-5 justify-content-center text-center bg-body-dark">
        <Col xs={12}>
          <h2>{siteTitle}</h2>
          <p className="fs-4 mb-3">Page Not Available</p>
          <p className="fs-5 mb-1">Sorry, can&apos;t find it.</p>
          <p className="fs-5 mb-3">Try one of these...</p>
          <Row className="g-2 justify-content-center text-nowrap">
            <Col className="flex-grow-0">
              <Button href={'/#projects'} variant="outline-primary">
                Projects
              </Button>
            </Col>
            <Col className="flex-grow-0">
              <Button href={'/#support'} variant="outline-primary">
                Support
              </Button>
            </Col>
            <Col className="flex-grow-0">
              <Button href={'/#contact'} variant="outline-primary">
                Contact
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  )
}

export default NotFound
